export const lang = {
    login_menu: 'Login',
    home: '抱抱转运',
    login_title: 'Member Login',
    login_email: 'Email Address',
    login_password: 'Password',
    login_notMember: 'Not a member?',
    login_register: 'Register',
    login_forgetPassword: 'Forget passward',
    login_subtitle: 'Reliable oversee transport',
}